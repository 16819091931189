.TextBox,
.ButtonBox,
.SingleImageBox,
.ProductListingBox,
.ProductRecommendationBox {
	clear: both;
}

.TextBox {
	font-size: 1.1rem;
	line-height: 1.6;
	padding: 0 1.2rem;
	margin-bottom: 3rem;

	@include breakpoint(medium) {
		margin: 0 12% 3rem;
		padding: 0 2.8rem;
	}

	@include breakpoint(large) {
		margin: 0 22% 3rem;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
		font-size: 1.8rem;
	}

	h1, h2, h3 {
		margin: 3rem 0 2rem;
	}
}

.ButtonBox {
	clear: both;
	padding: 0 1.2rem;
	margin-bottom: 3rem;

	@include breakpoint(medium) {
		margin: 0 12% 3rem;
		padding: 0 2.8rem;
	}

	@include breakpoint(large) {
		margin: 0 22% 3rem;
	}

	.button {
		margin: 0;
		text-transform: uppercase;
		font-weight: 600;
	}
}

.SingleImageBox {
	text-align: center;
	padding: 0 1.2rem;
	margin-bottom: 3rem;

	@include breakpoint(medium) {
		margin: 0 12% 3rem;
		padding: 0 2.8rem;
	}

	@include breakpoint(large) {
		margin: 0 22% 3rem;
	}

	&.align-c {
		clear: both;
	}

	&.align-l {
		float: left;
		margin: 0 2rem 1.5rem 0;
		max-width: 50%;
	}

	&.align-r {
		float: right;
		margin: 0 0 1.5rem 2rem;
		max-width: 50%;
	}

	img {
		margin: 0;
	}
}

.HtmlBox {
	clear: both;
	padding: 0 1.2rem;
	margin-bottom: 3rem;

	@include breakpoint(medium) {
		margin: 0 12% 3rem;
		padding: 0 2.8rem;
	}

	@include breakpoint(large) {
		margin: 0 22% 3rem;
	}

	.instagram-media {
		position: relative!important;
		display: inline-block!important;
	}

	iframe {
		position: relative!important;
	}
}

.ProductListingBox {
	clear: both;
	margin-bottom: 3rem;

	.product-item {

		.product-image-wrapper img {
			filter: brightness(1) saturate(1);
			-webkit-filter: brightness(1) saturate(1);
			-moz-filter: brightness(1) saturate(1);
			-o-filter: brightness(1) saturate(1);
		}

		.product-shipping_costs {
			display: none;
		}
	}
}

.ProductRecommendationBox {
	background: $light-gray;
	position: relative;
	overflow: hidden;
	padding: 0 1.2rem;
	margin-bottom: 3rem;

	@include breakpoint(medium) {
		margin: 0 12% 3rem;
		padding: 0 2.8rem;
	}

	@include breakpoint(large) {
		margin: 0 22% 3rem;
	}

	a {
		color: $black;

		&:hover {
			.button {
				background: lighten($primary-color, 10);
			}
			img {
				-webkit-transform:scale(1.05);
				-moz-transform:scale(1.05);
				-ms-transform:scale(1.05);
				-o-transform:scale(1.05);
				transform:scale(1.05);
			}
		}
	}

	.product-headline {
		font-weight: 600;
		color: $medium-gray;
	}

	.product-brand {
		font-weight: 600;
		margin: 2rem 0 0;
		font-size: 1.4rem;
		text-transform: uppercase;
		letter-spacing: .05rem;
	}

	.product-title {
		font-weight: 600;
		margin: 1rem 0;
		font-size: 1.4rem;
		line-height: 1.3;
		text-transform: uppercase;
		letter-spacing: .05rem;
		color: $medium-gray;
	}

	.price {
		font-weight: 700;
		display: block;
		margin: .5rem 0 .3rem;
		font-size: 1.6rem;
		padding: 0;
		position: relative;

		@include breakpoint(small only) {
			font-size: 1rem;
		}

		.amount {
			position: relative;

			b {
				font-weight: 400;
			}

			small {
				position: relative;
				top: -.5em;
				font-size: .6em;
				display: inline-block;
				margin-left: .1em;
			}

			.sizes {
				position: absolute;
				top: auto;
				bottom: rem-calc(-8);
				left: 0;
				right: 0;
				color: $dark-gray;
				text-shadow: none;
			}
		}

		del {
			position: relative;
			top: rem-calc(-6);
			text-decoration: none;
			font-size: 1rem;

			@include breakpoint(small only) {
				font-size: .85rem;
			}

			&:after {
				content: '';
				position: absolute;
				height: rem-calc(2);
				left: rem-calc(8);
				right: rem-calc(8);
				top: 50%;
				margin-top: -1px;
				background: $sale;
				z-index: 4;
				transform: rotate(-8deg);
			}
		}

		ins {
			text-decoration: none;
			font-size: 1.4rem;
			color: $sale;
			margin-right: .4rem;

			.amount {
				transform: rotate(0deg);

				&:before {
					background: url('../img/price-sale.svg') no-repeat center!important;
					transform: rotate(0)!important;
				}

				&:after {
					background: url('../img/price.svg') no-repeat center!important;
					transform: rotate(0)!important;
				}
			}
		}
	}

	.product-image {
		img {
			-webkit-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
		}
	}

	.button {
		display: inline-block;
		margin-top: 2rem;
		text-transform: uppercase;
	}
}