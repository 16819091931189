.breadcrumbs {
	display: inline-block;
	margin: 0;

    @include breakpoint(small only) {
    	display: none;
    }

    @include breakpoint(medium only) {
    	display: none;
    }

	li {
		font-weight: 600;

		&:before {
			content: '»';
			display: inline-block;
			margin: 0 .5rem 0 .3rem;
			font-weight: 400;
			color: $medium-gray;
		}

		&:first-child {
			font-weight: 400;

			&:before {
				display: none;
			}
		}

		a {
			color: $black;
		}

		a:hover {
			text-decoration: none;
			color: $medium-gray;
		}

		&:nth-child(1n+3):before {
			content: '»';
			font-weight: 400;
			display: inline-block;
			margin-right: .3rem;
		}

		&.current {
			color: $medium-gray;
		}

		&:last-child a {
			color: $medium-gray;
		}
	}
}