.blog-item {
	position: relative;
	display: block;

	@include breakpoint(medium) {
		margin-bottom: 2.5rem;
	}

	&:hover {
		.overlay {
			box-shadow: 0 0 10px rgba(0,0,0,.2);
		}
	}

	&.big {
		.overlay .title {
			font-size: rem-calc(24);
		}
	}

	img {
		position: relative;
	}
	
	.overlay {
		background: $white;
		position: relative;
		padding: 1.2rem 1.8rem .6rem;
		z-index: 2;
		
		@include breakpoint(small only) {
			top: rem-calc(-20);
			margin-left: rem-calc(20);
			margin-right: rem-calc(20);
		}

		@include breakpoint(medium only) {
			bottom: rem-calc(-1);
		}

		@include breakpoint(medium) {
			position: absolute;
			right: 0;
			left: 40%;
			bottom: 0;
			padding: 1.7rem 2.8rem 1.1rem;
			-webkit-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
		}

		.title {
			font-size: rem-calc(18);
			font-family: $highlight-font-family;
			margin-bottom: .7rem;
			line-height: 1.3;
		}
		
		.link {
			text-transform: uppercase;
		}

	}

}