.product-item {
 	color: $black;
 	display: block;
 	position: relative;
 	margin-top: 2rem;
 	margin-bottom: 2.4rem;
 	text-align: center;

 	@include breakpoint(small only) {
 		margin-bottom: 1.5rem;
 	}

 	&:hover {

 		img {
			-webkit-transform:scale(1.1);
			-moz-transform:scale(1.1);
			-ms-transform:scale(1.1);
			-o-transform:scale(1.1);
			transform:scale(1.1);
 		}

 		.price {
			color: $black;
		}

 		.product-button {
	 		background-color: $primary-color;
	 		color: $white;
	 	}
	}

	&.column:last-child:not(:first-child),
	&.columns:last-child:not(:first-child) {
		float: left!important;
	}

	.sale-percent {
		@extend .radius;
		position: absolute;
		color: $white;
		background: $sale;
		display: block;
		text-align: center;
		text-transform: uppercase;
		font-weight: 600;
		font-size: .85rem;
		padding: .3em .4em;
		margin-top: -1.5rem;
	}

	.product-image-wrapper {
		margin-bottom: .75rem;
		position: relative;
		text-align: center;

		@include breakpoint(small only) {
			min-height: auto;
		}

		img {
			max-width: rem-calc(220);
			width: 100%;
			-webkit-transition: all .2s ease;
			-moz-transition: all .2s ease;
			-ms-transition: all .2s ease;
			-o-transition: all .2s ease;
			transition: all .2s ease;
		}
	}

	.product-brand {
		font-weight: 600;
		margin: 1.5rem 0 0;
		font-size: .9rem;
		text-transform: uppercase;
		letter-spacing: .05rem;
	    color: $medium-gray;
	}

	.product-title {
		width: 100%;
		margin: 0;
		font-size: .9rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    color: $medium-gray;
	}

	.price {
		font-weight: 600;
		display: block;
		margin: 0 0 .3rem;
		font-size: 1.1rem;
		padding: 0;
		position: relative;

		@include breakpoint(small only) {
			font-size: 1rem;
		}

		.amount {
			position: relative;

			b {
				font-weight: 400;
			}

			small {
				position: relative;
				top: -.5em;
				font-size: .6em;
				display: inline-block;
				margin-left: .1em;
			}

			.sizes {
				position: absolute;
				top: auto;
				bottom: rem-calc(-8);
				left: 0;
				right: 0;
				color: $dark-gray;
				text-shadow: none;
			}
		}

		del {
			position: relative;
			text-decoration: none;
			font-size: 1.1rem;

			@include breakpoint(small only) {
				font-size: 1rem;
			}

			&:after {
				content: '';
				position: absolute;
				height: rem-calc(2);
				left: rem-calc(2);
				right: rem-calc(2);
				top: 50%;
				background: $sale;
				z-index: 4;
			}
		}

		ins {
			text-decoration: none;
			font-size: 1.1rem;
			color: $sale;
			margin-right: .4rem;

			.amount {
				transform: rotate(0deg);

				&:before {
					background: url('../img/price-sale.svg') no-repeat center!important;
					transform: rotate(0)!important;
				}

				&:after {
					background: url('../img/price.svg') no-repeat center!important;
					transform: rotate(0)!important;
				}
			}
		}
	}

	.partner-shop {
		clear: both;
		color: $medium-gray;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: .05rem;
		font-size: .65rem;
		margin: 0 0 .5rem;
		line-height: 1.3;
	}

	.product-shipping_costs {
		clear: both;
		color: $medium-gray;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: .05rem;
		font-size: .65rem;
		margin: 0;
		line-height: 1.3;

		&.free {
			color: $black;
		}

		small {
			font-size: 100%;
		}
	}
}