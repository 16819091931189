a, button, input, textarea {
	outline: none!important;
}

.radius {
	border-radius: rem-calc(4);
}

.section {
	@include breakpoint(medium) {
		margin-bottom: 2rem;
	}
}

.button.secondary {
	color: #fff;

	&:hover {
		color: #fff; 
	}
}

.button [class^="icon-"] {
	margin-right: .3rem;
	font-size: .95em;
}

.text-bold {
	font-weight: 600;
}

table {
	font-size: .9em;
	width: 100%;

	.button {
		margin: 0;

		[class^="icon-"] {
			margin: 0;
		}
	}
}

.headline {
	margin: 0;
    line-height: 2.4;
    font-family: $highlight-font-family;
    font-size: rem-calc(24);
    text-align: center;
}

.callout {
	&.warning {
		border-color: $warning-color;
	}
	&.alert {
		border-color: $alert-color;
	}
	&.primary {
		border-color: $primary-color;
	}
}