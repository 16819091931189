.pagination {
	
	&.pagination-top {
		margin: rem-calc(-5) 0 .75rem;
	}

	li {
		font-size: .9rem;
	}

	li:not([class^="pagination"]) {
		a:not(.current):hover {
			background: $primary-color;
		}
	}

	a {
		padding: .2rem .6rem;

		&.current {
			background: none;
			font-weight: 600;
		}
	}

	.button {
		padding: .8rem 1rem;
		text-transform: uppercase;
		font-weight: 600;

		&.next {
			margin-left: 1rem;
		}

		&.prev {
			margin-right: 1rem;
		}
	}
}