.categories-item {
	margin-bottom: 2.5rem;
	border: 1px solid $light-gray;
	padding: 4rem 2.5rem;
	text-align: center;

	.title {
		font-size: rem-calc(24);
		font-family: $highlight-font-family;
		margin-bottom: .7rem;
		line-height: 1.3;
	}

	ul {
		margin: 0;
	}

	li {
		text-transform: uppercase;
		font-size: 1.1rem;
		letter-spacing: .1rem;
		display: inline-block;
		list-style-type: none;
		margin: 0 .5rem;
	}
}