.brand-item {
	@include breakpoint(large) {
		&:nth-child(4n + 5) {
			clear: both;
		}
	}

	@include breakpoint(medium only) {
		&:nth-child(3n + 4) {
			clear: both;
		}
	}

	@include breakpoint(small only) {
		&:nth-child(2n + 3) {
			clear: both;
		}
	}

	&:last-child {
		float: left;
	}
}