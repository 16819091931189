.blog-post-template {

	.blog-item.big .overlay {
		@include breakpoint(medium) {
			left: 22%;
			padding-right: 31%;
		}
	}

	.post-title {
		font-size: 2.4rem;
		color: $secondary-color;
		font-weight: 600;
		margin: 0 0 1rem;
		line-height: 1.2;
	}

	.blog-category {
		color: $medium-gray;
		font-weight: 600;
		text-transform: uppercase;
		font-size: .85rem;

		&:before {
			content: '//';
			display: inline-block;
			margin-right: .2rem;
		}
	}

	.featured-image {
		display: block;
		float: left;
		margin: 0 2rem 2rem 0;
		max-width: 50%;

		@include breakpoint(small only) {
			max-width: 100%;
			width: 100%;
		}
	}

	.excerpt {
		position: relative;
		margin: 0 22% 2rem;
		padding: 0 2.8rem;
		font-size: 1.1rem;
		line-height: 1.4;
	}
}