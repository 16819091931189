.color-list,
.farbe-list {
	max-height: none!important;
	padding: .5rem 0!important;
}

.color-item,
.farbe-item {
	padding: 0!important;
	margin: 0;
	border: rem-calc(5) solid $white;
	float: left;
	width: 100%;
	max-width: rem-calc(40);
	transition: border .1s ease;

	&:hover {
		border-width: rem-calc(2);
	}

	a {
		line-height: 0;
		padding: 0!important;
		font-size: 0!important;
		display: block;
		box-shadow: inset 0 0 rem-calc(6) rgba(0,0,0,.15);

		img {
			opacity: 0;
			width: 100%;
			height: auto;
		}
	}

}

.color-weiß,
.farbe-weiß {
	background: #fff;
}
.color-anthrazit,
.color-schwarz,
.farbe-anthranzit,
.farbe-schwarz {
	background: #000;
}
.color-beige,
.farbe-beige {
	background: #d4b98b;
}
.color-creme,
.farbe-creme {
	background: #FFF6E7;
}
.color-blau,
.farbe-blau {
	background: #1B4CA2;
}
.color-braun,
.farbe-braun {
	background: #613b0f;
}
.color-bunt,
.color-mehrfarbig,
.farbe-bunt,
.farbe-mehrfarbig {
	background: rgb(211,42,42);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d32a2a', endColorstr='#d129d1',GradientType=1 );
	background: -moz-linear-gradient(left,  rgba(211,42,42,1) 0%, rgba(211,42,42,1) 12%, rgba(247,168,49,1) 13%, rgba(247,168,49,1) 25%, rgba(247,168,49,1) 25%, rgba(247,240,49,1) 26%, rgba(247,240,49,1) 38%, rgba(247,240,49,1) 39%, rgba(62,204,40,1) 40%, rgba(62,204,40,1) 53%, rgba(40,201,188,1) 54%, rgba(40,201,188,1) 69%, rgba(39,68,198,1) 70%, rgba(39,68,198,1) 84%, rgba(209,41,209,1) 85%, rgba(209,41,209,1) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(211,42,42,1)), color-stop(12%,rgba(211,42,42,1)), color-stop(13%,rgba(247,168,49,1)), color-stop(25%,rgba(247,168,49,1)), color-stop(25%,rgba(247,168,49,1)), color-stop(26%,rgba(247,240,49,1)), color-stop(38%,rgba(247,240,49,1)), color-stop(39%,rgba(247,240,49,1)), color-stop(40%,rgba(62,204,40,1)), color-stop(53%,rgba(62,204,40,1)), color-stop(54%,rgba(40,201,188,1)), color-stop(69%,rgba(40,201,188,1)), color-stop(70%,rgba(39,68,198,1)), color-stop(84%,rgba(39,68,198,1)), color-stop(85%,rgba(209,41,209,1)), color-stop(100%,rgba(209,41,209,1)));
	background: -webkit-linear-gradient(left,  rgba(211,42,42,1) 0%,rgba(211,42,42,1) 12%,rgba(247,168,49,1) 13%,rgba(247,168,49,1) 25%,rgba(247,168,49,1) 25%,rgba(247,240,49,1) 26%,rgba(247,240,49,1) 38%,rgba(247,240,49,1) 39%,rgba(62,204,40,1) 40%,rgba(62,204,40,1) 53%,rgba(40,201,188,1) 54%,rgba(40,201,188,1) 69%,rgba(39,68,198,1) 70%,rgba(39,68,198,1) 84%,rgba(209,41,209,1) 85%,rgba(209,41,209,1) 100%);
	background: -o-linear-gradient(left,  rgba(211,42,42,1) 0%,rgba(211,42,42,1) 12%,rgba(247,168,49,1) 13%,rgba(247,168,49,1) 25%,rgba(247,168,49,1) 25%,rgba(247,240,49,1) 26%,rgba(247,240,49,1) 38%,rgba(247,240,49,1) 39%,rgba(62,204,40,1) 40%,rgba(62,204,40,1) 53%,rgba(40,201,188,1) 54%,rgba(40,201,188,1) 69%,rgba(39,68,198,1) 70%,rgba(39,68,198,1) 84%,rgba(209,41,209,1) 85%,rgba(209,41,209,1) 100%);
	background: -ms-linear-gradient(left,  rgba(211,42,42,1) 0%,rgba(211,42,42,1) 12%,rgba(247,168,49,1) 13%,rgba(247,168,49,1) 25%,rgba(247,168,49,1) 25%,rgba(247,240,49,1) 26%,rgba(247,240,49,1) 38%,rgba(247,240,49,1) 39%,rgba(62,204,40,1) 40%,rgba(62,204,40,1) 53%,rgba(40,201,188,1) 54%,rgba(40,201,188,1) 69%,rgba(39,68,198,1) 70%,rgba(39,68,198,1) 84%,rgba(209,41,209,1) 85%,rgba(209,41,209,1) 100%);
	background: linear-gradient(to right,  rgba(211,42,42,1) 0%,rgba(211,42,42,1) 12%,rgba(247,168,49,1) 13%,rgba(247,168,49,1) 25%,rgba(247,168,49,1) 25%,rgba(247,240,49,1) 26%,rgba(247,240,49,1) 38%,rgba(247,240,49,1) 39%,rgba(62,204,40,1) 40%,rgba(62,204,40,1) 53%,rgba(40,201,188,1) 54%,rgba(40,201,188,1) 69%,rgba(39,68,198,1) 70%,rgba(39,68,198,1) 84%,rgba(209,41,209,1) 85%,rgba(209,41,209,1) 100%);
}
.color-gelb,
.farbe-gelb {
	background: #FFEB00;
}
.color-gold,
.farbe-gold {
	background: #fefcea;
	background: -moz-linear-gradient(-45deg,  #fefcea 0%, #f1da36 100%);
	background: -webkit-linear-gradient(-45deg,  #fefcea 0%,#f1da36 100%);
	background: linear-gradient(135deg,  #fefcea 0%,#f1da36 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefcea', endColorstr='#f1da36',GradientType=1 );
}
.color-kupfer,
.farbe-kupfer {
	background: #fff8ed;
	background: -moz-linear-gradient(-45deg,  #fff8ed 0%, #b75b2a 100%);
	background: -webkit-linear-gradient(-45deg,  #fff8ed 0%,#b75b2a 100%);
	background: linear-gradient(135deg,  #fff8ed 0%,#b75b2a 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff8ed', endColorstr='#b75b2a',GradientType=1 );
}
.color-silber,
.farbe-silber {
	background: #ededed;
	background: -moz-linear-gradient(-45deg,  #ededed 0%, #8e8e8e 100%);
	background: -webkit-linear-gradient(-45deg,  #ededed 0%,#8e8e8e 100%);
	background: linear-gradient(135deg,  #ededed 0%,#8e8e8e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#8e8e8e',GradientType=1 );
}
.color-transparent,
.farbe-transparent {
	background: #ffffff;
	background: -moz-linear-gradient(-45deg,  #ffffff 0%, #ffffff 31%, #d6d6d6 32%, #d6d6d6 35%, #ffffff 36%, #ffffff 42%, #efefef 43%, #efefef 46%, #ffffff 47%, #ffffff 54%, #d6d6d6 55%, #d6d6d6 58%, #ffffff 59%);
	background: -webkit-linear-gradient(-45deg,  #ffffff 0%,#ffffff 31%,#d6d6d6 32%,#d6d6d6 35%,#ffffff 36%,#ffffff 42%,#efefef 43%,#efefef 46%,#ffffff 47%,#ffffff 54%,#d6d6d6 55%,#d6d6d6 58%,#ffffff 59%);
	background: linear-gradient(135deg,  #ffffff 0%,#ffffff 31%,#d6d6d6 32%,#d6d6d6 35%,#ffffff 36%,#ffffff 42%,#efefef 43%,#efefef 46%,#ffffff 47%,#ffffff 54%,#d6d6d6 55%,#d6d6d6 58%,#ffffff 59%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );
}
.color-grau,
.farbe-grau {
	background: #CCC;
}
.color-grün,
.color-gruen,
.color-grun,
.farbe-grpn,
.farbe-gruen
.farbe-grun {
	background: #238f3c;
}
.color-lila,
.color-violett,
.farbe-lila,
.farbe-violett {
	background: #B15AE8;
}
.color-orange,
.farbe-orange {
	background: #e27d00;
}
.color-pink,
.farbe-pink {
	background: #E43DA1;
}
.color-rosa,
.farbe-rosa {
	background: #FFB8E2;
}
.color-rot,
.farbe-rot {
	background: #ee3c33;
}
.color-türkis,
.color-tuerkis,
.color-turkis,
.farbe-türkis,
.farbe-tuerkis,
.farbe-turkis {
	background: #00FFD0;
}
