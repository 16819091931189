[class*="product-list-"] {
	&.no-bottom .product-item {
		margin-bottom: 0;
	}
}

.product-list-2 {

	.product-item {
		@extend .large-6;
		@extend .medium-6;
		@extend .small-6;
		
		@include breakpoint(large) {
			&:nth-child(2n + 3) {
				clear: both;
			}
		}

		@include breakpoint(medium only) {
			&:nth-child(2n + 3) {
				clear: both;
			}
			&:last-child {
				display: none;
			}
		}

		@include breakpoint(small only) {
			&:nth-child(2n + 3) {
				clear: both;
			}
		}
	}
}

.product-list-3 {

	.product-item {
		@extend .large-4;
		@extend .medium-6;
		@extend .small-6;
		
		@include breakpoint(large) {
			&:nth-child(3n + 4) {
				clear: both;
			}
		}

		@include breakpoint(medium only) {
			&:nth-child(2n + 3) {
				clear: both;
			}
			&:last-child {
				display: none;
			}
		}

		@include breakpoint(small only) {
			&:nth-child(2n + 3) {
				clear: both;
			}
		}
	}
}

.product-list-4 {

	.product-item {
		@extend .large-3;
		@extend .medium-4;
		@extend .small-6;
		
		@include breakpoint(large) {
			&:nth-child(4n + 5) {
				clear: both;
			}
		}

		@include breakpoint(medium only) {
			&:nth-child(3n + 4) {
				clear: both;
			}
			&:last-child {
				display: none;
			}
		}

		@include breakpoint(small only) {
			&:nth-child(2n + 3) {
				clear: both;
			}
		}
	}
}

.product-list-6 {
	margin-botom: 1rem;

	.product-item {
		@extend .large-2;
		@extend .medium-3;
		@extend .small-6;
		
		@include breakpoint(large) {
			&:nth-child(6n + 7) {
				clear: both;
			}
		}

		@include breakpoint(medium only) {
			&:nth-child(4n + 5) {
				clear: both;
			}
			&:nth-child(6),
			&:nth-child(5) {
				display: none;
			}
		}

		@include breakpoint(small only) {
			&:nth-child(2n + 3) {
				clear: both;
			}
		}
	}
}