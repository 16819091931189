.off-canvas-button {
	color: $black;
	height: rem-calc(44);
	width: rem-calc(44);
	line-height: rem-calc(26);
	text-align: center;
	font-size: 1.2rem;
	margin-top: rem-calc(-4);

	@include breakpoint(small only) {
		position: absolute;
		left: 0;
		top: rem-calc(5);
	}

	@include breakpoint(medium only) {
		left: auto;
		top: auto;
		width: auto;
		height: rem-calc(36);
		line-height: rem-calc(36);
		font-weight: 600;
	}

	.burger {
		height: 2px;
		width: rem-calc(18);
		background: $black;
		display: inline-block;
		position: relative;

		@include breakpoint(medium only) {
			width: rem-calc(16);
			background: $primary-color;
			top: rem-calc(-9);
			margin-right: .3rem;
		}

		&:after,
		&:before {
			content: '';
			height: 2px;
			width: rem-calc(18);
			background: $black;
			position: absolute;
			top: rem-calc(6);
			left: 0;

			@include breakpoint(medium only) {
				width: rem-calc(16);
				background: $primary-color;
				top: rem-calc(5);
			}
		}

		&:after {
			top: rem-calc(12);
			
			@include breakpoint(medium only) {
				top: rem-calc(10);
			}
		}
	}
}

header {
	padding: .5rem 0 1rem;
	padding: 2rem 0 1rem; // remove if pricealart and outifts ready to deploy
	position: relative;
	background: $white;
	border-bottom: 1px solid $light-gray;
	margin-bottom: 1rem;

	@include breakpoint(small only) {
		text-align: center;
		padding-top: .8rem;
		padding-bottom: .8rem;
	}

	@include breakpoint(medium only) {
		padding: 2rem 0 0;
	}

	.medium-12.column {
		position: relative;
	}

	.open-search-button {
		position: absolute;
		color: $black;
		right: 0;
		top: 0;
		font-size: 1.2rem;
		line-height: rem-calc(48);
		height: rem-calc(44);
		width: rem-calc(44);
	}

	.logo { 
		color: $black;
		position: relative;
		margin-bottom: 1rem;
		display: inline-block;
		top: rem-calc(3);

		img {
			max-width: rem-calc(130);
			
			@include breakpoint(small only) {
				max-width: rem-calc(100);
				position: relative;
			}

			@include breakpoint(medium only) {
				max-width: 90%;
			}
		}
	}

	.pos {
		p {
			margin: 0;
		}
	}

	form {
		margin: 0!important;
		position: absolute;
		top: 0;
		right: 0;

		@include breakpoint(small only) {
			display: none!important;
		}

		button {
			position: absolute;
			right: rem-calc(-9);
			top: rem-calc(0);
			padding: rem-calc(10);

			img {
				max-width: rem-calc(16);
			}
		}

		input[type="text"] {
			font-size: .9rem!important;
			height: 2.85rem!important;
			box-shadow: none!important;
			padding-left: 0;
			padding-right: rem-calc(20);
			border: none!important;
			border-bottom: 1px solid $medium-gray!important;
			transition: all .5s ease;

			&:focus {
				text-align: left;
				border-bottom: 1px solid $black!important;
			}
		}
	}

	ul.menu {
		text-align: right;
		margin: .3rem 0 1rem;

		li {
			display: inline-block;

			a {
				font-size: .85rem;
				text-transform: uppercase;
				font-weight: 600;
				letter-spacing: .05em;

			}
			a:not(.button) {
				color: $black;
				
				&.active a,
				&:hover a {
					color: $primary-color;
				}
			}
		}
	}

	.menu-button {
		position: absolute;
		right: 1.17188rem;
		bottom: .4rem;
	}
}

.subheader {
	margin: -1rem 0 2.5rem;
	background: $white;
	border-bottom: 1px solid $light-gray;
	z-index: 4;
	position: relative;

	@include breakpoint(small only) {
		margin-bottom: .5rem;
	}

	@include breakpoint(medium only) {
		margin-bottom: 1rem;
	}

	.menu {
		text-align: center;
		margin: 0;

		> li > ul {
			text-align: left;
		}

		> li.has-submenu > a:after {
			margin-top: rem-calc(1);
		}
		
		li {
			list-style-type: none;
			margin-left: 1rem;
			margin-right: 1rem;
			display: inline-block;

			@include breakpoint(small only) {
				padding-right: 0;
			}

			a {
				font-weight: 600;
				letter-spacing: .05rem;
				color: $black;
				font-size: 1rem;
				text-transform: uppercase;

				&:hover {
					color: $medium-gray;
				}
			}


			.sale {
				color: $sale;
				padding-left: 2rem;

				&:before {
					content: '';
					width: 11px;
					height: 11px;
					position: absolute;
					margin-left: -15px;
					margin-top: 0px;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url('https://www.mode24.de/static/base/assets/img/sale.svg');
				}
			}

			.magazin {
				color: $medium-gray;

				&:hover {
					color: lighten($medium-gray, 15);
				}
			}

			> ul {
				margin: 0;
				padding: .7rem 1rem .45rem;
				box-shadow: 0 1px 1px rgba(0,0,0,.1);

				> li {
					margin: 0;
					padding: .2rem 0;

					a {
						font-size: .95rem;
					}
				}
			}
		}
	}
}
