.menu.sort-products {
	margin-top: rem-calc(-5);
	
	@include breakpoint(small only) {
		margin-bottom: 1rem;
		text-align: center;
	}

	li {
		display: inline-block;

		&:first-child span {
			padding-left: 0!important;
		}

		a, 
		span {
			padding: .3rem .4rem!important;
			font-size: .9rem!important;
			line-height: 1;
		}

		a {
			color: $black;
			font-weight: 600;
		}
	}
}