footer {
	margin-top: 2rem;
	padding-bottom: 1rem;
	border-top: 1px solid $light-gray;

	.callout {
		padding: .5rem 2rem 2rem;
	}


	ul.menu {
		
		&.pre-menu {
			margin-top: 1rem;
		}

		li {
			font-size: .95rem;

			// &:first-child {
			// 	font-weight: 600;
			// 	text-transform: uppercase;
			// 	margin: 1.5rem 0 1rem;
			// }

			a {
				padding-left: 0!important;
			}
		}
	}
}