.product-detail-site {
	
	.product-infos .product-brand {
		font-size: 2.2rem;
	}

	.product_title {
		font-size: 2.2rem;
		line-height: 1;
	}

	.product-item.product-infos {
		text-align: left;
	}

	h3.subtitle {
		font-size: 1rem;
		color: $medium-gray;
	}

	.image-container {
		text-align: center;
		margin-bottom: 2rem;
	}

	.product-meta {
		border-top: 1px solid $medium-gray;
		border-bottom: 1px solid $medium-gray;
		padding: 1rem 0;
		margin: 1.5rem 0 ;
		position: relative;

		.price {
			border: none;
			margin: 0;

			.amount {
				font-size: 1.8rem;
			}

			del .amount {
				font-size: 1.1rem;
			}
		}

		.button {
			font-size: 1.1rem;
			margin: 0;
			font-weight: 600;
		}
	}

	.product-recommendation-title {
		@include breakpoint(small only) {
			display: none;
		}
	}
	.product-recommendation {
		@extend .hide-for-small-only;
		
		.column.product-item {
			@extend .large-2;
			@extend .medium-4;

			@include breakpoint(large) {
				&:nth-child(6n + 7) {
					clear: both;
				}
			}

			@include breakpoint(medium only) {
				&:nth-child(2n + 3) {
					clear: both;
				}
				&:last-child {
					display: none;
				}
			}
		}
	}

}