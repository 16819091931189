.shop-title {
	font-size: 1.3em;
	font-weight: 600;
	margin: 2rem 0;
	padding-bottom: .5rem;
	text-align: center;
	border-bottom: 1px solid $light-gray;

	@include breakpoint(small only) {
		font-size: 1rem;
		font-weight: 600;
		margin: .8rem 0;
	}

	@include breakpoint(medium only) {
		margin-bottom: 1rem;
	}

	nav, p, .breadcrumbs {
		display: inline-block;
		margin: 0;
		line-height: 1;
	}

	small {
		font-size: .9rem;
		display: inline-block;
		margin-left: .6rem;
		color: $dark-gray;
		font-weight: 400;
		position: relative;
		top: rem-calc(-2);
	}
}

.sub-header-small {
	@include breakpoint(small only) {
		text-align: center;
	}
}

.open-sidebar-button {
	color: $black;

	@include breakpoint(large) {
		font-size: 1.2rem;
	}

	@include breakpoint(small only) {
		display: inline-block;
		font-weight: 600;
		font-size: 1.1rem;
		height: rem-calc(42);
		line-height: rem-calc(44);
		margin-bottom: .5rem;
	}

	@include breakpoint(medium only) {
		font-weight: 600;
		font-size: .9rem;
		position: relative;
		top: rem-calc(-3);
	}
	
	i {
		font-size: 1.4rem;

		@include breakpoint(small only) {
			position: relative;
			top: rem-calc(1);
			margin-left: .3rem;
		}

		@include breakpoint(medium only) {
			font-size: 1.2rem;
			position: relative;
			top: rem-calc(2);
			color: $black;
			margin-right: .1rem;
		}
	}
}