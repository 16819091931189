.banner {

	&.fullscreen {
		position: relative;
		display: block;
		color: $black;
		margin-bottom: 2.5rem;

		p {
			position: absolute;
			top: 4rem;
			right: 4rem;
			line-height: 1;
			font-size: 2rem;
		}

		.button {
			margin-top: 1rem;
			font-size: .9rem;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}