.content-text {
	color: $medium-gray;
	margin-top: 2.5rem;
	font-size: .9rem;
	column-count: 4;
	column-gap: rem-calc(30);

	@include breakpoint(small only) {
		column-count: 1;
	}

	@include breakpoint(medium only) {
		column-count: 2
	}

	a {
		color: $dark-gray;
	}

	h1, h2, h3, h4, h5, h6 {
		font-size: 1rem;
		font-weight: 400;
		margin-top: 0;
		margin-bottom: .5rem;
		line-height: 1.4;
	}
}