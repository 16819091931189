.cookie-warning {
	position: fixed;
	bottom: rem-calc(-100);
	opacity: 0;
	right: rem-calc(20);
	background: $white;
	z-index: 999;
	padding: 0 0 1rem;
	-webkit-transition: opacity .5s ease;
	-moz-transition: opacity .5s ease;
	-o-transition: opacity .5s ease;
	transition: opacity .5s ease;
	border-top: 2px solid $secondary-color;
	width: rem-calc(200);
	left: auto;
	background: #fff;
	box-shadow: 0 2px 3px rgba(0,0,0,.1);
	border-radius: 4px;
	border: 1px solid #efefef;

	&.active {
		bottom: rem-calc(20);
		opacity: 1;
	}

	p {
		font-size: .95em;
		margin: 1rem 0 0;
		line-height: 1.4em;
	}

	.button {
		margin-bottom: 0;
	}

	@media #{$small-only} {
		p {
			font-size: .7em;
			margin-right: .5em;
			line-height: 1.3em;
		}
	}

	@media #{$medium-only} {
		p {
			font-size: .7em;
			margin-right: .5em;
			line-height: 1.3em;
		}
	}
}