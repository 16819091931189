.standard-page {
	.pagetitle {
		font-size: 2rem;
		font-family: $highlight-font-family;
		margin-bottom: 2rem;
	}

	.callout {
		margin-top: 2rem;
		padding: 2rem;
	}

	ul li a {
		color: $black;

		&:hover {
			color: $medium-gray;
		}
	}
}