.categories-list {
	@extend .clearfix;
	border: 3px solid $light-gray;
	padding: 1.5rem 2rem;
	margin: 0 0 2.5rem;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: right;

	li {
		list-style-type: none;
		margin: 0;
		font-size: 1.3rem;
		text-transform: uppercase;
		letter-spacing: .05rem;
		font-weight: 600;
		color: $primary-color;

		&:nth-child(1n+7) {
			display: none;
		}

		@include breakpoint(large) {
			&:nth-child(1n+2) {
				float: left;
				width: 36%;
				display: block;
			}
		}

		a {
			margin-top: .75rem;
			display: inline-block;
			font-size: 1rem;
			color: $black;

			&:hover {
				color: $medium-gray;
			}
		}
	}
}