.off-canvas-wrapper {
	background: $white;
}

.off-canvas-content {
	box-shadow: -2px 0 0 rgba(0,0,0,.05)!important;
}

.off-canvas {
	form {
		padding: 1rem 1rem 0;

		.input-group-field {
			font-size: .9rem;
		}

		.input-group-button input {
			height: rem-calc(40);
		}
	}

	.menu {
		> li > ul {
			margin-left: rem-calc(30);
		}
	}
}

.js-off-canvas-exit {
	z-index: 99999;
}