.orbit {

	.orbit-nav {
		@extend .clearfix;
		margin: 0 0 2.3rem;
		position: relative;
		
		li {
			float: left;
			width: 33.33333%;
			list-style-type: none;

			button {
				width: 100%;
				height: auto;
				margin: 0;
				display: block;
				line-height: 1.4;
				padding: 1rem;
				font-size: 1rem;
				border-radius: 0;
				background: $white;
				border-top: 1px solid $light-gray;
				border-bottom: 1px solid $light-gray;

				&.is-active,
				&:hover {
					background: $light-gray;
				}
			}
		}
	}
}