.sidebar-container {
	@include breakpoint(small only) {
		max-height: 0;
		overflow: hidden;
		transition: all .75s ease;
		position: relative;

		&.open {
			max-height: rem-calc(1500);
		}
	}

	@include breakpoint(medium only) {
		left: -100%;
		position: absolute;
		z-index: 9999;
		margin-top: 9.25rem;
		transition: left .5s ease;
		background: $white;
		padding-top: 2rem;

		&.open {
			left: 0;
		}
	}
}

.sidebar {
	padding: 0;

	@include breakpoint(small only) {
		padding-top: 1rem;
	}

	.sidebar-item {
		margin-bottom: 3rem;

		.sidebar-title {
			margin-bottom: .3rem;
			font-size: 1.1rem;
			letter-spacing: .1rem;
			color: $dark-gray;
			text-transform: uppercase;
			line-height: 1;
			margin-bottom: 1rem;
			padding-bottom: .5rem;
			border-bottom: 1px solid $light-gray;
		}

		.sidebar-item-content {

			@include breakpoint(medium only) {
				padding: 0;
			}
			
			&.no-padding {
				padding: 0;
			}
		}
		
		.list-search {
			font-size: .9rem;
			margin: .5rem 0 0;
			border-radius: rem-calc(5);
			height: rem-calc(32);
			position: relative;
			z-index: 2;

			&:focus {
				position: relative;
				z-index: 3;
			}
		}

		.list {
			border: 1px solid $light-gray;
			border-top: none;
			margin: rem-calc(-2) 0 .4rem;
			max-height: 12rem;
			overflow-y: scroll;
			padding: .2rem 0;

			li {
				list-style-type: none;

				a {
					color: $black;
					padding: .3rem .6rem;
					font-size: .9rem;
					display: block;

					&:hover {
						color: $medium-gray;
					}
				}

				&:nth-child(2n) {
					background: lighten($light-gray, 3%);
				}
			}
		}

		&.sidebar-menu {
			padding-top: .2rem;
			padding-bottom: .3rem;

			.menu .button {
				margin-top: .6rem;
			}
		}

		&.selected-filter {

			.sidebar-item-content {
				padding-bottom: .3rem;
			}

			ul {
				margin: 0 0 .4rem;
				padding: 0;
			}

			li {
				display: inline-block;
				padding: .5rem 0 0;

				a {
					@extend .radius;
					@extend .button;
					padding-right: 2rem;
					text-transform: uppercase;
					font-weight: 600;
					background: $primary-color;
					font-size: .9rem;
					position: relative;
					display: inline-block;

					&:after {
						content: '';
						height: 1px;
						width: 12px;
						position: absolute;
						background: darken($primary-color, 40%);
						transform: rotate(45deg);
						right: rem-calc(7);
						top: rem-calc(14);
					}

					&:before {
						content: '';
						height: 1px;
						width: 12px;
						position: absolute;
						background: darken($primary-color, 40%);
						transform: rotate(-45deg);
						right: rem-calc(7);
						top: rem-calc(14);
					}
				}
			}
		}
	}

	.title {
		margin-bottom: .3rem;
		font-size: 1.2rem;
		font-family: $highlight-font-family;
	}

	.menu {
		.button {
			font-weight: 600;
			font-size: .9rem;
			text-transform: uppercase;
			margin-bottom: 1.5rem;
		}

		> a {
			margin-bottom: .5rem;
		}

		li {
			&:last-child a {
				padding-bottom: 0;
			}

			&:first-child a {
				padding-top: 0;
			}

			&.active a {
				color: $medium-gray;
				cursor: default;
			}

			a {
				padding: .5rem;
				padding-left: 0!important;
				color: $black;
				font-weight: 600;
			}

			small {
				color: $medium-gray;
			}
		}
	}

	.price-range {
		padding-top: 0;

		.slider {
			margin-bottom: 1.5rem;
		}

		input {
			width: 45%;
			display: inline-block;
			background: none;
			font-size: .9rem;
			text-align: center;
			height: rem-calc(33);
		}

		.button,
		input {
			margin: 0;
		}

		.button {
			text-transform: uppercase;
			font-weight: 600;
		}
	}

	.attribute-list {
		margin: 0 0 .5rem;
		max-height: 160px;
		overflow-y: scroll;
		border-bottom: 1px solid $medium-gray;

		li {
			list-style-type: none;
			font-size: .85rem;
			background: lighten($light-gray, 4);

			&:hover span {
				color: $primary-color;
			}

			span {
				display: block;
				padding: .4rem .5rem;
				cursor: pointer;
			}

			&:nth-child(2n) {
				background: $light-gray;
			}
		}
	}

	.property {
		clear: both;
		
		.property-text {
			clear: both;
			font-size: .95em;
			line-height: 1.6em;
			color: $dark-gray;

			h1, h2, h3, h4, h5, h6 {
				font-size: 1em;
				font-weight: 700;
				color: $black;
			}

			p {
				line-height: 1.6em;
				color: $dark-gray;
			}
		}

	}

	#rangeSlider {
		margin: rem-calc(10) rem-calc(8) rem-calc(20);

		&.noUi-target,
		&.noUi-target * {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-ms-touch-action: none;
		-ms-user-select: none;
		-moz-user-select: none;
		-moz-box-sizing: border-box;
			box-sizing: border-box;
		}
		&.noUi-target {
			position: relative;
			direction: ltr;
		}
		.noUi-base {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
		}
		.noUi-origin {
			position: absolute;
			right: 0;
			top: 0;
			left: 0;
			bottom: 0;
		}
		.noUi-handle {
			position: relative;
			z-index: 1;
		}
		.noUi-stacking .noUi-handle {
			z-index: 10;
		}
		.noUi-state-tap .noUi-origin {
		-webkit-transition: left 0.3s, top 0.3s;
			transition: left 0.3s, top 0.3s;
		}
		.noUi-state-drag * {
			cursor: inherit !important;
		}

		.noUi-base {
			-webkit-transform: translate3d(0,0,0);
			transform: translate3d(0,0,0);
		}

		&.noUi-horizontal {
			height: 5px;
		}
		&.noUi-horizontal .noUi-handle {
			width: 18px;
			height: 18px;
			left: -8px;
			top: -7px;
		}

		&.noUi-background {
			background: #EEE;
		}
		.noUi-connect {
			background: #CCC;
			-webkit-transition: background 450ms;
			transition: background 450ms;
		}
		.noUi-origin {
			border-radius: 10px;
		}
		&.noUi-target {
			border-radius: 10px;
		}
		.noUi-target.noUi-connect {
		}

		.noUi-dragable {
			cursor: w-resize;
		}
		.noUi-vertical .noUi-dragable {
			cursor: n-resize;
		}
		.noUi-handle {
			border-radius: 20px;
			background: $dark-gray;
			cursor: pointer;
		}
		.noUi-active {
			background: $primary-color;
		}

		[disabled].noUi-connect,
		[disabled] .noUi-connect {
			background: #B8B8B8;
		}
		[disabled].noUi-origin,
		[disabled] .noUi-handle {
			cursor: not-allowed;
		}
	}
}